import { ChangeEventHandler, FC, FocusEvent, FocusEventHandler, Ref, useState } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { Label } from "../Label/Label";
import { Text } from "../Text/Text";
import { Icon, IconProps } from "../Icon/Icon";
import { Spinner } from "../Spinner/Spinner";
import { Message } from "../Message/Message";
import { Feedback } from "../Feedback/Feedback";
import { IconButton, IconButtonProps } from "../IconButton/IconButton";

export interface TextInputProps {
  name: string;
  label?: string;
  type?: "text" | "textarea" | "email" | "tel" | "password";
  autoComplete?: "off" | "current-password" | "new-password";
  inputRef?: Ref<any>;
  value?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  externalInput?: boolean;
  warningTitle?: string;
  warningMessage?: string;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  rightBtn?: IconButtonProps;
  leftIcon?: IconProps;
  leftChild?: string;
  iconState?: "default" | "loading" | "warning";
  className?: string;
}

export const TextInput: FC<TextInputProps> = ({
  name,
  label = "",
  type = "text",
  autoComplete = "off",
  inputRef,
  value = "",
  description = "",
  placeholder = "",
  required = false,
  externalInput = false,
  warningTitle,
  warningMessage,
  errorMessage = "",
  disabled = false,
  onChange = (): void => {},
  onBlur = (): void => {},
  onFocus = (): void => {},
  onClear = (): void => {},
  showClearBtn = false,
  clearLabel,
  rightBtn,
  leftIcon,
  leftChild,
  iconState = "default",
  className,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const hasIndicator = leftChild || leftIcon;
  // Recommended by web.dev (https://web.dev/sign-in-form-best-practices/#new-password)
  const id = autoComplete !== "off" ? autoComplete : name;

  const iconClasses = classNames(
    "-mr-0.5 flex w-12 shrink-0 items-center justify-center rounded-l border-1.5 text-gray-400 transition group-hover:ring-0.5",
    iconState === "warning"
      ? {
          "border-yellow-500 bg-yellow-50 text-yellow-500": true,
          "group-hover:ring-yellow-500": !disabled,
          "ring-0.5 ring-yellow-500": focus,
          "z-10": !focus,
        }
      : {
          "border-gray-200 bg-gray-50 group-hover:ring-gray-200": true,
          "ring-0.5 ring-gray-200": focus,
        },
    leftChild && {
      "font-semibold overflow-hidden": true,
      "text-xl": leftChild.length === 1,
      "text-lg tracking-wider": leftChild.length > 1,
    },
  );

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setFocus(false);
    onBlur(e);
  };
  const handleFocus = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setFocus(true);
    onFocus(e);
  };

  const getInput = (): JSX.Element => {
    const inputClasses = classNames(
      "block h-12 w-full resize-none border-1.5 p-3.5 transition",
      "text-sm text-gray-700 outline-none placeholder:text-gray-400",
      hasIndicator ? "rounded-r" : "rounded",
      disabled
        ? "cursor-not-allowed border-gray-200 bg-gray-100"
        : "focus:border-blue-500 focus:bg-white focus:ring-0.5 focus:ring-blue-500 group-hover:ring-0.5 focus:group-hover:ring-blue-500",
      externalInput && !errorMessage && "bg-gray-100",
      errorMessage && !disabled
        ? {
            "border-red-500 group-hover:ring-red-500": true,
            "bg-red-50": hasIndicator,
            "bg-red-100": !hasIndicator,
          }
        : "border-gray-200 group-hover:ring-gray-200",
    );

    if (type === "textarea") {
      return (
        <TextareaAutosize
          name={name}
          id={id}
          className={inputClasses}
          value={value}
          minRows={disabled ? 1 : 2}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          autoComplete="off"
          disabled={disabled}
          ref={inputRef}
        />
      );
    }

    return (
      <input
        id={id}
        name={name}
        className={inputClasses}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        ref={inputRef}
      />
    );
  };

  return (
    <div className={className} {...props}>
      <Label
        htmlFor={id}
        label={label}
        required={required}
        showClearBtn={showClearBtn}
        onClear={onClear}
        clearLabel={clearLabel}
      />
      <div className="group relative flex">
        {hasIndicator && (
          <div className={iconClasses}>
            {iconState === "loading" ? (
              <Spinner color="current" className="size-6" />
            ) : (
              (leftIcon && <Icon {...leftIcon} />) ?? leftChild
            )}
          </div>
        )}
        {getInput()}
        {rightBtn && (
          <div className="absolute inset-y-0 right-1 flex items-center">
            <IconButton {...rightBtn} size="md" />
          </div>
        )}
      </div>

      {errorMessage && <Feedback status="error" message={errorMessage} />}
      {warningMessage && <Message type="warning" title={warningTitle} text={warningMessage} className="mt-2" />}
      {description && !errorMessage && (
        <Text className="mt-1" size="sm" color="medium">
          {description}
        </Text>
      )}
    </div>
  );
};
